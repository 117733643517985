import React, { useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


let tg = window.Telegram.WebApp;

tg.MainButton.text = "Отправить"; //изменяем текст кнопки 
tg.MainButton.setText("Отправить"); //изменяем текст кнопки иначе
tg.MainButton.textColor = "#FFFFFF"; //изменяем цвет текста кнопки
tg.MainButton.color = "#143F6B"; //изменяем цвет бэкграунда кнопки
tg.MainButton.setParams({"color": "#143F6B"}); 





const Information = () => {


    
   

  return (
    <Grid container  sx={{ pb:20, pt:2 }} >


      <Box sx={{ pb:2, pl:3, pr:3 }}>
      <Typography gutterBottom variant="h5" component="div">
      АРЕНДА И ПРОДАЖА КОММЕРЧЕСКОЙ НЕДВИЖИМОСТИ В МОСКВЕ
       </Typography>
       <Typography variant="body4" color="text.secondary" sx={{ mt:3 }}>
       Недвижимость всегда обеспечивает стабильность инвестору. Коммерческая недвижимость формата street retail – самый надежный, простой и эффективный инструмент инвестиций. Доходность торгового помещения в локации с высоким трафиком может достигать 15% годовых.
R4S – собственник широкого пула объектов стрит ритейла в Москве.  За почти 20-летний опыт работы компания вывела свою формулу успеха –  объект стрит ритейла: 1 этаж, интенсивный пешеходный и автомобильный трафик. Главные активы компании – это безупречная репутация, профессионализм и качество продукта.  

Торговое помещение в хорошей локации – это стабильный и высокодоходный актив. Для своего бизнеса вы можете выбрать один из удобных для вас вариантов вложения средств.

Компания предлагает в аренду торговые помещения различной площади на первых фасадных линиях домов в локациях со сложившимся торговым окружением. Помещения свободного назначения идеально подойдут для организации любого бизнеса.

Один из наиболее популярных инструментов для инвестора – приобретение готового арендного бизнеса с качественным сетевым арендатором, который приносит стабильный доход с первого дня владения.

Специалисты компании проводят полный перечень мероприятий по подготовке объектов к продаже и сдаче в аренду. Все помещения многофункционального назначения оснащены современными инженерными системами, имеют большие фасадные витрины, отдельный доступ с улицы, возможность размещения собственной вывески и необходимые документы.

Мы заботимся о своих клиентах на всех этапах: гарантируем чистоту сделки, обеспечиваем юридическое сопровождение, подготовку полного пакета документов и постпродажный сервис.

Наши специалисты готовы предоставить подробную информацию обо всех предложениях торговых помещений
по телефону: +7 (495) 324-71-06.
        
       </Typography>
       
      </Box>

      <Grid xs={12} style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}} sx={{ pt:2, pb:2 }}> 
      <p>КОНТАКТЫ</p>
      </Grid>
      <Box sx={{ pb:2, pl:3 }}>
      <Typography gutterBottom variant="h5" component="div">
      +7 (495) 324-71-06
       </Typography>
       <Typography variant="body4" color="text.secondary" sx={{ mt:3 }}>
       Москва, Большая Никитская, 16
        
       </Typography>
       <Typography variant="body2" color="text.secondary" sx={{ pt:1 }}>
       
        ежедневно с 9:00 до 21:00
       </Typography>
      </Box>
    </Grid>
  );
};
 
export default Information;