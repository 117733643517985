import logo from './logo.svg';
import styles from './App.css';
import Navbar from './components/Navbar';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate}
from 'react-router-dom';

import Home from './pages';
import Presentation from './pages/presentation';
import NewProducts from './pages/newProducts';
import Information from './pages/Information';

// const server = App();
// server.use(cors());


function App() {
  return (
      <Router >
      <Navbar />
      <Routes >
            
          <Route exact path='/' element={ <Navigate to="/presentation" /> } />
          <Route path='/presentation' element={<Presentation/>} />
          <Route path='/newproducts' element={<NewProducts/>} />
          <Route path='/information' element={<Information/>} />
          
          
      </Routes>
      </Router>
  );
  }
   
  export default App;


