import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';



let tg = window.Telegram.WebApp;

tg.MainButton.text = "Отправить"; //изменяем текст кнопки 
tg.MainButton.setText("Отправить"); //изменяем текст кнопки иначе
tg.MainButton.textColor = "#FFFFFF"; //изменяем цвет текста кнопки
tg.MainButton.color = "#143F6B"; //изменяем цвет бэкграунда кнопки
tg.MainButton.setParams({"color": "#143F6B"}); 





const NewProducts = () => {
  const [value, setValue] = useState([])

  useEffect(() => {
    axios.get("https://r4s.r4s-feeds.ru/bot/")
   .then( response => {setValue(response.data)})
  },[])
    
   console.log(value)

  return (
    <Grid container style={{ height: '100%', width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center'}} alignItems="center" justify="center" sx={{ pb:20, pt:2 }} >
     
      {value.length == 0 ? 
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pt:20 }} >
      <CircularProgress />
    </Box>
     : <>
      <p>НАШИ НОВЫЕ ПРЕДЛОЖЕНИЯ</p>
     <Box >
          <h3 style={{  marginBottom:0 }}>АРЕНДА</h3>
          <h3 style={{  marginTop:0 }}>НЕЖИЛЫХ ПОМЕЩЕНИЙ</h3>
          
          {value.map((res) => (

            res.name == 'аренда'? (res.objnames.map((item) => (

              <Card sx={{ maxWidth: 345, mb:2  }} alignItems="center" justify="center">
              <CardMedia
                sx={{ height: 240 }}
                image={item.document}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item.address}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.text}
                </Typography>
              </CardContent>
              <CardActions>
                <a href={item.url}><Button size="small">Подробнее</Button></a>
              </CardActions>
            </Card>


            ))): ''


          ))}
          



      </Box>
      <Box >
          <h3 style={{  marginBottom:0 }}>ПРОДАЖА</h3>
          <h3 style={{  marginTop:0 }}>КОММЕРЧЕСКОЙ НЕДВИЖИМОСТИ</h3>
          {value.map((res) => (

            res.name == 'продажа'? (res.objnames.map((item) => (

              <Card sx={{ maxWidth: 345, mb:2  }} alignItems="center" justify="center">
              <CardMedia
                sx={{ height: 240 }}
                image={item.document}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item.address}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.text}
                </Typography>
              </CardContent>
              <CardActions>
                <a href={item.url}><Button size="small">Подробнее</Button></a>
              </CardActions>
            </Card>


            ))): ''


          ))}
      </Box>

      <Box >
          <h3>ГОТОВЫЙ АРЕНДНЫЙ БИЗНЕС</h3>
          {value.map((res) => (

            res.name == 'ГАБ'? (res.objnames.map((item) => (

              <Card sx={{ maxWidth: 345, mb:2  }} alignItems="center" justify="center">
              <CardMedia
                sx={{ height: 240 }}
                image={item.document}
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {item.address}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.text}
                </Typography>
              </CardContent>
              <CardActions>
                <a href={item.url}><Button size="small">Подробнее</Button></a>
              </CardActions>
            </Card>


            ))): ''


          ))}
      </Box>
     
     </>}
      
      
    </Grid>
  );
};
 
export default NewProducts;