import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { blueGrey } from '@mui/material/colors';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


let bez = []

function Presentation() {
  const [value, setValue] = useState([])
  const [checked, setChecked] = React.useState([]);
  const [obj, setObj] = React.useState('Rent');
  const [valueMenu, setValueMenu] = React.useState('1');
  const [skont, setSkont] = React.useState(true)
  const [bezkont, setBezkont] = React.useState(false)
  const [search, setSearch] = React.useState(false)


const keyClick = (event) => { 
  // console.log(event)
  // console.log("textContent>>>", event.target.textContent)
  
  if (event.target.textContent == undefined || event.target.textContent == '' || event.target.textContent == ' '){
    
    if (obj == 'Rent' &&  !bezkont){
    setChecked([])
      const urlrent = "https://rent.r4s-feeds.ru/api/rent/"
    axios.get(urlrent).then( response => {setValue(response.data)})

    value.map((item) => {
      if (item.name[item.name.length - 5] != "_"){
        bez.push(item)
      }})
    }
    else if (obj == 'Rent' &&  bezkont){
      setChecked([])
      const urlrent = "https://rent.r4s-feeds.ru/api/rentbez/"
    fetch(urlrent)
    .then( response => {response.json().then(r => {setValue(r)})})

    value.map((item) => {
      if (item.name[item.name.length - 5] != "_"){
        bez.push(item)
      }})
    }

    else if (obj == 'Sale' &&  !bezkont){
      setChecked([])
      const urlrent = "https://rent.r4s-feeds.ru/api/sale/"
      axios.get(urlrent)
     .then( response => {setValue(response.data)})

     value.map((item) => {
      if (item.name[item.name.length - 5] != "_"){
        bez.push(item)
      }})
    }
    else if (obj == 'Sale' &&  bezkont){
      setChecked([])
      const urlrent = "https://rent.r4s-feeds.ru/api/salebez/"
    fetch(urlrent)
    .then( response => {response.json().then(r => {setValue(r)})})

    value.map((item) => {
      if (item.name[item.name.length - 5] != "_"){
        bez.push(item)
      }})
    }

    else if (obj == 'Pab' &&  !bezkont){
      setChecked([])
      const urlrent = "https://rent.r4s-feeds.ru/api/pab/"
      axios.get(urlrent)
     .then( response => {setValue(response.data)})

     value.map((item) => {
      if (item.name[item.name.length - 5] != "_"){
        bez.push(item)
      }})
    }
    else if (obj == 'Pab' &&  bezkont){
      setChecked([])
      if (obj == 'Pab'){
        const urlrent = "https://rent.r4s-feeds.ru/api/pabbez/"
        fetch(urlrent)
        .then( response => {response.json().then(r => {setValue(r)})})
      }
      value.map((item) => {
        if (item.name[item.name.length - 5] != "_"){
          bez.push(item)
        }})

    }


    console.log('bez>>>>', bez)

  }
  else{
    
    setChecked([])
    setValue(value.filter(item => item.name == event.target.textContent))
    
  }
  

    
}


const clickSearch = () => {
  setSearch(!search)
}


const ClickSkontakt = () =>{
  setChecked([])
  setSearch(false)
  setSkont(!skont)
  setBezkont(!bezkont)
  if (obj == 'Rent'){
    const urlrent = "https://rent.r4s-feeds.ru/api/rent/"
    axios.get(urlrent)
   .then( response => {setValue(response.data)})
  }
  if (obj == 'Sale'){
    const urlrent = "https://rent.r4s-feeds.ru/api/sale/"
    axios.get(urlrent)
   .then( response => {setValue(response.data)})
  }
  if (obj == 'Pab'){
    const urlrent = "https://rent.r4s-feeds.ru/api/pab/"
    axios.get(urlrent)
   .then( response => {setValue(response.data)})
  }
}

const ClickBezkontakt = () =>{
  setSearch(false)
  setChecked([])
  setSkont(!skont)
  setBezkont(!bezkont)
  if (obj == 'Rent'){
    const urlrent = "https://rent.r4s-feeds.ru/api/rentbez/"
    fetch(urlrent)
    .then( response => {response.json().then(r => {setValue(r)})})
  }
  if (obj == 'Sale'){
    const urlrent = "https://rent.r4s-feeds.ru/api/salebez/"
    fetch(urlrent)
    .then( response => {response.json().then(r => {setValue(r)})})
  }
  if (obj == 'Pab'){
    const urlrent = "https://rent.r4s-feeds.ru/api/pabbez/"
    fetch(urlrent)
    .then( response => {response.json().then(r => {setValue(r)})})
  }
  
}


  const handleChangeMenu = (event, newValue) => {
    setValueMenu(newValue);
  };


  const handleDelete = (event, name) =>{
    
    setChecked(checked.filter(item => item !== name))
  }


  const clickImgChecked = (e, name) => {
    if (checked.includes(name)){
      setChecked(checked.filter(item => item !== name))
    }
    else{
      setChecked(prevArray => [...prevArray, name])
      tg.MainButton.show()
    }
   
  }

  const handleChange = (event) => {
   
    if (checked.includes(event.target.name)){
      
      setChecked(checked.filter(item => item !== event.target.name))
    }
    else{
      setChecked(prevArray => [...prevArray, event.target.name])
      tg.MainButton.show()
    }
    
    
    
    // setChecked(event.target.checked);
  };



  const handleChangeSale = (event) => {
   
    if (checked.includes(event.target.name)){
      
      setChecked(checked.filter(item => item !== event.target.name))
    }
    else{
      setChecked(prevArray => [...prevArray, event.target.name])
      tg.MainButton.show()
    }
    
    
    
    // setChecked(event.target.checked);
  };



  let tg = window.Telegram.WebApp;
 
    
    
  tg.MainButton.text = "Скачать"; //изменяем текст кнопки 
  tg.MainButton.setText("Скачать"); //изменяем текст кнопки иначе
  tg.MainButton.textColor = "#000000"; //изменяем цвет текста кнопки
  tg.MainButton.color = "#CED8DD"; //изменяем цвет бэкграунда кнопки
  tg.MainButton.setParams({"color": "#CED8DD"}); 
  tg.expand()
  // setValue (r.data.replace(/[\n\r]+/g, '').split(';')) 

  if (checked.length === 0){
    tg.MainButton.hide()
  }

// const url = "https://p.r4s.ru/api/rent/"
//  const urlrent = "/api/rent"

  useEffect(() => {
    axios.get("https://rent.r4s-feeds.ru/api/rent/")
   .then( response => {setValue(response.data)})
  },[])



  const ClickRent = () => {
    setSearch(false)
    setObj("Rent")
    setChecked([])

    const urlrent = "https://rent.r4s-feeds.ru/api/rent/"
    axios.get(urlrent)
   .then( response => {setValue(response.data)})
    setSkont(true)
    setBezkont(false)
    }

    const ClickSale = () => {
      setSearch(false)
      setObj("Sale")
      setChecked([])
      const urlrent = "https://rent.r4s-feeds.ru/api/sale/"
      axios.get(urlrent)
     .then( response => {setValue(response.data)})
      setSkont(true)
      setBezkont(false)
      }

    const ClickPab = () => {
      setSearch(false)
      setObj("Pab")
      setChecked([])
      const urlrent = "https://rent.r4s-feeds.ru/api/pab/"
    axios.get(urlrent)
   .then( response => {setValue(response.data)})
      setSkont(true)
      setBezkont(false)
      }

  const sendDataToTelegram = () => {
    tg.sendData(`{ "${obj}": "${checked}", 
    "bezkont": "${bezkont}",}`);
    
  }


  React.useEffect(() => {
    tg.onEvent('mainButtonClicked', sendDataToTelegram)
    return () => {
    tg.offEvent('mainButtonClicked', sendDataToTelegram)
    }
  }, [sendDataToTelegram])


React.useEffect(
  () => {
    bez = []
    
    value.map((item) => {
    if (item.name[item.name.length - 5] != "_"){
      bez.push(item)
    }}
    )
    
  }
)





  return (
  <Box>
              <TabContext value={valueMenu} style={{background: '#FFFFFF'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", position: 'fixed', top: 0, }} style={{'z-index':'2000', background: '#FFFFFF'}}>
                  <TabList onChange={handleChangeMenu} aria-label="lab API tabs example full width" variant="fullWidth">
                    <Tab label="АРЕНДА" value="1" onClick={ClickRent}/>
                    <Tab label="ПРОДАЖА" value="2" onClick={ClickSale} />
                    <Tab label="ГАБ" value="3" onClick={ClickPab}/>
                  </TabList>





                </Box>
                <Box sx={{ position: 'fixed', top: 48,  display: 'flex', flexDirection: 'row', alignItems: 'center',}} style={{'z-index':'2000', background: '#DAE1E5', width: "100%"}}>
                      <Button  variant={skont?"contained":"outlined"} size="small" sx={{m:1, width: "100%",}} onClick={ClickSkontakt}>с контактами</Button>
                      <Button   variant={bezkont?"contained":"outlined"} size="small"sx={{m:1, width: "100%",}} onClick={ClickBezkontakt}>без контактов</Button>
                      <FindInPageIcon color="primary" sx={{m:1, }} onClick={clickSearch}/>

                </Box>
                {search?
                <Box sx={{ position: 'fixed', top: 94,  display: 'flex', flexDirection: 'row', alignItems: 'center',}} style={{'z-index':'2100', background: '#FFFFFF', width: "100%"}}>
                

                <Autocomplete
                      
                      onChange={keyClick}
                      
                      id="combo-box-demo"
                      options={value.map((option) => option.name)}
                      sx={{ m:2, width: '100%' }}
                      size="small"
                      renderInput={(params) => <TextField {...params} label="Адрес" />}
                    />

                </Box>:""}

                <Box sx={search?{ position: 'fixed', top: 160,  display: 'flex', flexDirection: 'row', alignItems: 'center',}:{ position: 'fixed', top: 92,  display: 'flex', flexDirection: 'row', alignItems: 'center',}} style={{'z-index':'1000', background: '#FFFFFF', width: "100%", 'overflow-x': 'auto',}}>
               
              
                {checked? checked.map((res, index) => <>
                  <Chip
                    name= {res}
                    sx={{ m:1, m:1}}
                    label={res}
                    color="success" size="small" variant="outlined"
                    // onClick={handleClickChip}
                    onDelete={(e) => handleDelete(e, res)}
                  />
                
                </>): ''} 
                
                </Box>

                <TabPanel value="1" style={{margin:0, padding:0}}>
                <Grid container style={{background: 'linear-gradient(to right bottom, #F6F6F6, #E6E6E6)'}} sx={checked.length >0 && search?{ pb:20, pt:25}: checked.length >0 && !search?{ pb:20, pt:16}:search?{ pb:20, pt:20}:{ pb:20, pt:12}}>

                      {value? value.map((res, index) => <>
                        
                        {(res )?( <Grid item xs={6}  sx={checked.includes(res.name)?{ p:1.8, background: '#E0EEDB'}:{ p:1.8, }}  key={index} >

                          <Box style={{height: '250px'}} onClick={(e)=>clickImgChecked(e, res.name)}>   
                          <img src={skont?('https://rent.r4s-feeds.ru/api/JPG/Rent/proxy1.php?url=https://p.r4s.ru/api/JPG/Rent/'+res.name.slice(0, -4)+'.jpg'):('https://rent.r4s-feeds.ru/api/JPG/Rent/proxy1.php?url=https://p.r4s.ru/api/JPG/Rentbk/'+res.name.slice(0, -4)+'.jpg')} alt={`${res.name}`} style={{width: '100%'}}/>
                        </Box > 
                          <Box sx={{mt:2}}>
                            <p style={{margin:0, padding:0, height: '40px', fontSize:14}}>{res.name}</p>
                            <Grid container  direction="row">
                              <Checkbox 
                              style={{marginLeft:0, paddingLeft:0}}
                              key={index}
                              name={res.name}
                              checked={checked.includes(res.name)? true:false}
                              onChange={handleChange}
                              color={checked.includes(res.name)?"success":"default"} 
                              />
                              {checked.includes(res.name)?<Typography style={{color:"#006837"}} sx={{mt:1.2}}>ВЫБРАНО</Typography>:<Typography sx={{mt:1.2}}>Выбрать</Typography>}
                            </Grid>
                          </Box>
                        </Grid>):''}
                        </>): ""} 
       
                  </Grid>
 
                </TabPanel>
                <TabPanel value="2" style={{margin:0, padding:0}}>
                <Grid container style={{background: 'linear-gradient(to right bottom, #F6F6F6, #E6E6E6)'}} sx={checked.length >0 && search?{ pb:20, pt:25}: checked.length >0 && !search?{ pb:20, pt:16}:search?{ pb:20, pt:20}:{ pb:20, pt:12}}>

                      {value? value.map((res, index) => <>

                        {(res)?( <Grid item xs={6}  sx={checked.includes(res.name)?{ p:1.8, background: '#E0EEDB'}:{ p:1.8, }} key={index} >

                          <Box style={{height: '250px'}} onClick={(e)=>clickImgChecked(e, res.name)}>   
                          <img src={skont?('https://rent.r4s-feeds.ru/api/JPG/Rent/proxy1.php?url=https://p.r4s.ru/api/JPG/Sale/'+res.name.slice(0, -4)+'.jpg'):('https://rent.r4s-feeds.ru/api/JPG/Rent/proxy1.php?url=https://p.r4s.ru/api/JPG/Salebk/'+res.name.slice(0, -4)+'.jpg')} alt={`${res.name}`} style={{width: '100%'}} />
                          </Box > 
                          <Box sx={{mt:2}}>
                            <p style={{margin:0, padding:0, height: '50px', fontSize:14}}>{res.name}</p>
                            <Grid container  direction="row">
                              <Checkbox 
                              style={{marginLeft:0, paddingLeft:0}}
                              key={index}
                              name={res.name}
                              checked={checked.includes(res.name)? true:false}
                              onChange={handleChangeSale}
                              color={checked.includes(res.name)?"success":"default"}  
                              />
                              {checked.includes(res.name)?<Typography style={{color:"#006837"}} sx={{mt:1.2}}>ВЫБРАНО</Typography>:<Typography sx={{mt:1.2}}>Выбрать</Typography>}
                            </Grid>
                          </Box>
                        </Grid>):''}
                        </>): ""} 

                </Grid>

                </TabPanel>
                <TabPanel value="3" style={{margin:0, padding:0}}>
                <Grid container style={{background: 'linear-gradient(to right bottom, #F6F6F6, #E6E6E6)'}} sx={checked.length >0 && search?{ pb:20, pt:25}: checked.length >0 && !search?{ pb:20, pt:16}:search?{ pb:20, pt:20}:{ pb:20, pt:12}}>

                  {value? value.map((res, index) => <>
                    
                    {(res)?( <Grid item xs={6}  sx={checked.includes(res.name)?{ p:1.8, background: '#E0EEDB'}:{ p:1.8, }}  key={index} >

                      <Box style={{height: '250px' }} onClick={(e)=>clickImgChecked(e, res.name)}>   
                      <img src={skont?('https://rent.r4s-feeds.ru/api/JPG/Rent/proxy1.php?url=https://p.r4s.ru/api/JPG/Pab/'+res.name.slice(0, -4)+'.jpg'):('https://rent.r4s-feeds.ru/api/JPG/Rent/proxy1.php?url=https://p.r4s.ru/api/JPG/Pabbk/'+res.name.slice(0, -4)+'.jpg')} alt={`${res.name}`} style={{width: '100%'}}/>
                    </Box > 
                      <Box sx={{mt:2}}>
                        <p style={{margin:0, padding:0, height: '50px', fontSize:14}}>{res.name}</p>
                        <Grid container  direction="row">
                          <Checkbox 
                          style={{marginLeft:0, paddingLeft:0}}
                          key={index}
                          name={res.name}
                          checked={checked.includes(res.name)? true:false}
                          onChange={handleChangeSale}
                          color={checked.includes(res.name)?"success":"default"} 
                          />
                          {checked.includes(res.name)?<Typography style={{color:"#006837"}} sx={{mt:1.2}}>ВЫБРАНО</Typography>:<Typography sx={{mt:1.2}}>Выбрать</Typography>}
                        </Grid>
                      </Box>
                    </Grid>):''}
                    </>): ""} 

                  </Grid>

                </TabPanel>
              </TabContext>


               




    </Box>

  );
}

export default Presentation;
