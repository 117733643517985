import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import DomainIcon from '@mui/icons-material/Domain';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { red } from '@mui/material/colors';
import Grid from '@material-ui/core/Grid';
import Paper from '@mui/material/Paper';


const color = red[500];



export default function Navbar() {
  const [value, setValue] = useState('presentation');
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    navigate(newValue);
    setValue(newValue);
  };


  const [valueTab, setValueTab] = useState('/presentation');

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
    navigate(newValue);
    setValue(newValue);
  };

  return (
    
        
              <Box sx={{ bgcolor: 'background.paper', width: "100%", position: 'fixed', bottom: 0, }} style={{'z-index':'2000',}}>
                <Tabs value={valueTab} onChange={handleChangeTab} variant="fullWidth" TabIndicatorProps={{sx:{backgroundColor:"#828282", height:"3px"}}}
                    sx={{ 
                    // задаем цвет со старта
                    "& button": {color:"#828282"},
                    "& button.Mui-selected": {color:"#000000"},
                    // Задаем цвет активной вкладке
                    "& button:focus": {color:"#000000"},
                
                    
                    }} aria-label="nav icon label tabs example full width">
                    <Tab icon={<BackupTableIcon />} label="Презентации" value="presentation"/>
                    <Tab icon={<StarBorderIcon />} label="Новинки" value="newproducts" />
                    <Tab icon={<TipsAndUpdatesOutlinedIcon />} label="Информация" value="information"/>
                </Tabs>
             
                </Box>
    
  );
}